import React from "react"
import Container from "./Container"
import { FaInstagram, FaTwitter, FaDiscord } from "react-icons/fa"
import Button from "./Button"

// for links we will need
// Instagram
// SolSea
// Twitter
// Discord
// Magic Eden
// Merch (bonfire)

const Navbar = ({ items, logo }) => {
  return (
    <nav>
      <div className=" items-center justify-between xl:flex flex-wrap">
        <div className="px-6 py-5 xl:pl-12 ">
          <img className="max-w-sm mx-auto w-full" src={logo} alt="Half Dead" />
        </div>
        <div className="flex items-center justify-center nav-items flex-wrap ">
          <a href="https://www.instagram.com/halfdeaddigital/" target="_blank">
            <FaInstagram fill="#fff" size={32} />
          </a>
          <a href="https://twitter.com/HalfDeadDigital" target="_blank">
            <FaTwitter fill="#fff" size={32} />
          </a>
          <a href="http://discord.gg/KBsVb7ajxw" target="_blank">
            <FaDiscord fill="#fff" size={32} />
          </a>
        </div>
        <div className=" large-nav-buttons xl:pr-12">
          <a
            href="https://solsea.io/collection/61d752106c6e081462271e52"
            target="_blank"
            className="nav-btn"
          >
            <Button className="btn-fill__black w-full">View On SolSea</Button>
          </a>

          <a
            href="https://magiceden.io/marketplace/half_dead"
            target="_blank"
            className="nav-btn"
          >
            <Button className="btn-fill__black w-full mt-4 md:mt-0">
              View On Magic Eden
            </Button>
          </a>

          <a
            href="https://www.bonfire.com/store/half-dead-digital-merch/"
            target="_blank"
            className="nav-btn"
          >
            <Button className="btn-fill__white w-full mt-4 md:mt-0">
              Half Dead Merch
            </Button>
          </a>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
