import React from "react"
import { FaInstagram, FaTwitter, FaDiscord } from "react-icons/fa"

const Footer = ({ logo }) => {
  const year = new Date().getFullYear()
  return (
    <footer className="footer">
      <div className="content mw">
        <div className="footer-logo">
          <img src={logo} alt="Half Dead" />
        </div>
        <div>
          <div className="flex items-center justify-center nav-items flex-wrap mt-4">
            <a
              href="https://www.instagram.com/halfdeaddigital/"
              target="_blank"
            >
              <FaInstagram fill="#fff" size={32} />
            </a>
            <a href="https://twitter.com/HalfDeadDigital" target="_blank">
              <FaTwitter fill="#fff" size={32} />
            </a>
            <a href="http://discord.gg/KBsVb7ajxw" target="_blank">
              <FaDiscord fill="#fff" size={32} />
            </a>
          </div>

          <p className="text-center text-white copyright-text">
            ©{year} Half Dead
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
